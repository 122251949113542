import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Button, Row, Col, InputGroup, FormControl, Breadcrumb } from 'react-bootstrap';
import { FaUser, FaLock } from 'react-icons/fa';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const LoginPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const jobId = location.state?.jobId;
  console.log("id", location.state);

  // Initial values for the form
  const initialValues = {
    email: '',
    password: '',
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Username is required')
      .min(4, 'Username must be at least 4 characters'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters'),
  });

  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000" // Local development
    : "https://www.mayuhrsolutions.com/api";

  // Handle form submission
  const handleSubmit = (values, { resetForm }) => {
    axios
      .post(`${apiUrl}/login`, {
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          // Display success alert
          Swal.fire({
            icon: 'success',
            title: 'Login Successful',
            text: 'You will be redirected shortly!',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              // Save JWT token in localStorage or state
              localStorage.setItem('token', response.data.token);

              // Redirect to the home page after the user clicks "OK"
              navigate(`/apply/${jobId}`);
            }
          });
        }
      })
      .catch((error) => {
        console.error('Error logging in:', error);

        // Display error alert
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: error.response && error.response.data
            ? error.response.data.message
            : 'Error logging in. Please try again.',
        });

        // Set an error message (optional)
        setErrorMessage(
          error.response && error.response.data
            ? error.response.data.message
            : 'Error logging in'
        );
      });

    resetForm(); // Optionally reset the form after submission
  };


  return (
    <Container className="mt-5">
      <h2 className="text-center mt-3" style={{color: '#8e44ad'}}>Login</h2>
      {/* <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Login</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row> */}
      <Row className="justify-content-center mt-3">
        <Col xs={12} md={6}>
          <div className="border shadow p-4 bg-white">
            {/* <h2 className="text-center mb-4">Login</h2> */}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  {/* Username Field */}
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                    <Field
                      name="email"
                      type="text"
                      placeholder="Enter your email"
                      className={`form-control ${
                        touched.email && errors.email ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </InputGroup>

                  {/* Password Field */}
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                    <Field
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                      className={`form-control ${
                        touched.password && errors.password ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </InputGroup>
                  <p className="text-center mt-3">
              
              {/* <Link to="/candidate-forgot-password">Forgot Password</Link> */}
            </p>

                  {/* Centered Login Button */}
                  <div className="d-flex justify-content-center">
                    <Button variant="primary" type="submit" className="mt-3">
                      Login
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>

            {/* Message if not registered */}
            <p className="text-center mt-3">
              If you are not registered, please{' '}
              <Link to="/candidate-registration">Register Here</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;