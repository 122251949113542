import React, { useEffect, useState } from "react";
import { Table, Button, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CandidateDashboard = () => {
  const [applications, setApplications] = useState([]);
  const [showApplications, setShowApplications] = useState(false); // State to toggle applications table
  const navigate = useNavigate();

  // Check if the app is running on localhost (development)
  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000" // Local development
    : "https://www.mayuhrsolutions.com/api";

  // Fetch candidate's own job applications
  useEffect(() => {
    if (showApplications) {
      const fetchApplications = async () => {
        const candidateEmail = localStorage.getItem("userEmail"); // Get email from localStorage

        if (!candidateEmail) {
          Swal.fire({
            icon: "error",
            title: "No Email Found",
            text: "Please log in or submit an application.",
          });
          return;
        }

        try {
          const response = await fetch(`${apiUrl}/api/applications/candidate/${candidateEmail}`);
          const data = await response.json();

          if (response.ok) {
            setApplications(data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Failed to fetch applications.",
            });
          }
        } catch (error) {
          console.error("Error fetching applications:", error);
          Swal.fire({
            icon: "error",
            title: "Network Error",
            text: "Could not fetch applications. Please check your network connection.",
          });
        }
      };

      fetchApplications();
    }
  }, [showApplications]); // Fetch applications only when 'showApplications' becomes true

  
  return (
    <div className="container mt-5">
      <h2 className="registration-heading mb-4">Dashboard</h2>
      
      {/* Row for the cards */}
      <Row className="mb-4">
        <Col md={6}>
          <Card className="m-3 p-4 shadow">
            <Card.Body>
              <Card.Title>View Jobs</Card.Title>
              <Card.Text>Explore and apply for jobs that match your profile.</Card.Text>
              <Button variant="primary" onClick={() => navigate("/joblisting")}>View Jobs</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="m-3 p-4 shadow">
            <Card.Body>
              <Card.Title>View Applications</Card.Title>
              <Card.Text>Review the status of your job applications.</Card.Text>
              <Button variant="success" onClick={() => setShowApplications(true)}>
                View Applications
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Conditionally render the table only when "showApplications" is true */}
      {showApplications && (
        <>
          <h2 className="registration-heading">Your Job Applications</h2>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Candidate Name</th>
                <th>Job ID</th>
                <th>Email</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              {applications.length === 0 ? (
                // Display message inside the tbody, right below the table header
                <tr>
                  <td colSpan="5" className="text-center">
                    No applications found.
                  </td>
                </tr>
              ) : (
                applications.map((application, index) => (
                  <tr key={application.id}>
                    <td>{index + 1}</td>
                    <td>{application.name}</td>
                    <td>{application.job_id}</td>
                    <td>{application.email}</td>
                    <td>
                      {application.reasons}
                      {/* <Button
                        variant="danger"
                        onClick={async () => {
                          // Handle delete application
                          const confirm = await Swal.fire({
                            title: "Are you sure?",
                            text: "This action cannot be undone!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, delete it!",
                          });
                          if (confirm.isConfirmed) {
                            try {
                              const response = await fetch(
                                `${apiUrl}/api/applications/${application.id}`,
                                { method: "DELETE" }
                              );
                              if (response.ok) {
                                setApplications((prev) =>
                                  prev.filter((app) => app.id !== application.id)
                                );
                                Swal.fire({
                                  icon: "success",
                                  title: "Deleted",
                                  text: "Application has been deleted.",
                                });
                              } else {
                                Swal.fire({
                                  icon: "error",
                                  title: "Failed",
                                  text: "Failed to delete application.",
                                });
                              }
                            } catch (error) {
                              console.error("Error deleting application:", error);
                            }
                          }
                        }}
                      >
                        Decline
                      </Button> */}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default CandidateDashboard;
