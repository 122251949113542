import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { FaBell, FaEnvelope, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CustomNavbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null); 
  console.log("role", userRole);
  const [users, setUsers] = useState(null);
  console.log("----->",users);
 

  const navigate = useNavigate();

  const isLocalhost = window.location.hostname === "localhost";
  const apiUrl = isLocalhost
    ? "http://localhost:5000" // Local development
    : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {

    // Fetch all users from the API when the component mounts
    const fetchAllUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/auth/users`); // Adjust the endpoint as necessary
        setUsers(response.data); 
        setUserRole(response.data[0].role);
        console.log('Fetched users:', response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchAllUsers(); 
  }, []); 

  const handleLogout = async () => {
    try {
      await axios.post('http://localhost:5000/api/auth/logout', {}, { withCredentials: true });
      localStorage.removeItem('userEmail'); // Clear email from storage
      setIsLoggedIn(false);
      setUserRole(null);
      navigate('/candidate-login'); // Redirect to login page
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };


  return (
    <Navbar variant="dark" expand="lg" style={{ backgroundColor: '#d69eed' }}>
      <Navbar.Brand href="/joblisting" className="ms-3 d-flex align-items-center">
        <img
          src="images/logo-transparent-png[1].png"
          alt="Logo"
          style={{ width: '200px', height: 'auto' }}
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav" className="justify-content-end">
        <Nav className="me-3 d-flex align-items-center">

            <>
              <Nav.Link href="#notifications">
                <FaBell size={20} color="black" />
              </Nav.Link>
              <Nav.Link href="#messages">
                <FaEnvelope size={20} color="black" />
              </Nav.Link>
              <NavDropdown title={<FaUser size={20} color="black" />} id="user-dropdown" align="end">
                <NavDropdown.Item href="#profile">Profile</NavDropdown.Item>
                <NavDropdown.Item href="#settings">Settings</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
