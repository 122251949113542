// components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="text-white py-4 mt-auto" style={{ fontSize: '0.9rem', height: '20px', }}>
      <Container>
        <Row>
          <Col className="text-center">
            <p className="text-black fw-4"><b>Contact Us</b></p>
          </Col>
          <Col className="text-center">
            <p>
              <FaEnvelope className=" text-black me-2" />
              <a href="mailto:info@example.com" className="text-black fw-4">
                <b>info@example.com</b>
              </a>
            </p>
          </Col>
          <Col className="text-center">
            <p>
              <FaPhone className="me-2 text-black" />
              <a href="tel:+1234567890" className="text-black">
                <b>+123 456 7890</b>
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
