import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HomePage from './components/Home';
import DailyAttendance from './components/DailyAttendence';
import PerformanceReport from './components/PerformanceReport';
import PayrollSheet from './components/PayrollSheet';
import LegalDocuments from './components/LegalDocuments';
import EmployeeProfile from './components/EmployeeProfile';
import Register from './components/Register';
import LoginPage from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import OtpVerification from './components/OtpVerification';
import EmployeeInfo from './components/EmployeeInfo';
import AdminDashboard from './components/AdminDashboard';
import JobListingPage from './components/JobListingPage';
import AddJobPage from './components/AddJobPage';
import ApplicationsPage from './components/ApplicationPage';
import CandidateRegistration from './components/CandidateRegistration';
import CandidateLogin from './components/CandidateLogin';
import OtpVerifyCandidate from './components/OtpVerifycandidate';
import CandidateDashboard from './components/CandidateDashboard';
import ViewApplication from './components/ViewApplication';
import JobApplicationForm from './components/JobApplicationForm';
import SelectedCandidate from './components/selectedCandidate';
import CustomNavbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import CandidateForgotPassword from './components/CandidateForgotPassword';

function App() {
  const [userData, setUserData] = useState(null); // Store user role
  const [loading, setLoading] = useState(true); // Loading state

  const isLocalhost = window.location.hostname === 'localhost';
  const apiUrl = isLocalhost
    ? 'http://localhost:5000' // Local development
    : 'https://www.mayuhrsolutions.com';
     

  // Fetch user role when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${apiUrl}/auth/getrole`, {
          method: 'GET',
          credentials: 'include', // Include credentials for session-based auth
        });
        const data = await response.json();
        if (data.success) {
          setUserData(data.role); // Set the role from the response
        } else {
          setUserData(null); // If no role is found, set to null
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false); // Stop loading once the request completes
      }
    };

    fetchUserData();
  }, [apiUrl]);

  // Handle logout and clear user data
  const handleLogout = () => {
    fetch(`${apiUrl}/api/auth/logout`, {
      method: 'POST',
      credentials: 'include', // Ensure session cookie is included
    })
      .then(() => {
        setUserData(null); // Clear user data on logout
        Navigate('/hr-login'); // Redirect to login
      })
      .catch((error) => console.error('Logout failed:', error));
  };

  // Show loading indicator while fetching user data
  // if (loading) return <div>Loading...</div>;

  return (
    <Router>
      <div id="root" className="d-flex flex-column min-vh-100">
        <CustomNavbar handleLogout={handleLogout} />
        <Container fluid className="d-flex flex-grow-1">
          <Row className="flex-grow-1">
            {/* Render Sidebar only for HR users */}
            {userData && userData.toUpperCase()  === 'HR' && (
              <Col md={3} className="sidebar-col">
                <Sidebar  />
              </Col>
            )}

            {/* Main Content Area */}
            <Col
              md={userData && userData.toUpperCase() === 'HR' ? 9 : 12}
              className="content-col"
              style={{ backgroundColor: 'rgb(235,237,244)' }}
            >
              <Routes>
                {/* Routes for HR */}
                {userData === 'HR' && (
                  <>
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/daily-attendance" element={<DailyAttendance />} />
                    <Route path="/payroll-sheet" element={<PayrollSheet />} />
                    <Route path="/legal-documents" element={<LegalDocuments />} />
                    <Route path="/employee-profile" element={<EmployeeProfile />} />
                    <Route path="/employee-info" element={<EmployeeInfo />} />
                    <Route path="/performance-report" element={<PerformanceReport />} />
                    <Route path="/admin-dashboard" element={<AdminDashboard />} />
                    <Route path="/view-application" element={<ViewApplication />} />
                    <Route path="/selected-candidate" element={<SelectedCandidate />} />
                    <Route path="/add-job" element={<AddJobPage />} />
                    <Route path="/add-job/:id" element={<AddJobPage />} />
                    <Route path="/applications/:jobId" element={<ApplicationsPage />} />
                    <Route path="/" element={<JobListingPage />} />
                <Route path="/joblisting" element={<JobListingPage />} />
                <Route path="/apply/:jobId" element={<JobApplicationForm />} />
                <Route path="/register" element={<Register />} />
                <Route path="/hr-login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/otp-verification" element={<OtpVerification />} />
                <Route path="/candidate-registration" element={<CandidateRegistration />} />
                <Route path="/otp-verify-candidate" element={<OtpVerifyCandidate />} />
                <Route path="/candidate-login" element={<CandidateLogin />} />
                <Route path="/candidate-forgot-password" element={<CandidateForgotPassword />} />
                <Route path="/candidate-dashboard" element={<CandidateDashboard />} />
                  </>
                )}

                {/* Routes for Candidates */}
                <Route path="/" element={<JobListingPage />} />
                <Route path="/joblisting" element={<JobListingPage />} />
                <Route path="/apply/:jobId" element={<JobApplicationForm />} />
                <Route path="/register" element={<Register />} />
                <Route path="/hr-login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/otp-verification" element={<OtpVerification />} />
                <Route path="/candidate-registration" element={<CandidateRegistration />} />
                <Route path="/otp-verify-candidate" element={<OtpVerifyCandidate />} />
                <Route path="/candidate-login" element={<CandidateLogin />} />
                <Route path="/candidate-forgot-password" element={<CandidateForgotPassword />} />
                <Route path="/candidate-dashboard" element={<CandidateDashboard />} />
              </Routes>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
