import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Col, Row, Container } from "react-bootstrap";
import { FaUser, FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ userId, handleLogout }) => {
  const [userData, setUserData] = useState({});
  console.log("user------>", userData);
  const [users, setUsers] = useState(null);
  console.log("users", users);
  const [profilePic, setProfilePic] = useState(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const isLocalhost = window.location.hostname === "localhost";
  const apiUrl = isLocalhost
    ? "http://localhost:5000" // Local development
    : "https://www.mayuhrsolutions.com/api";

  // Fetch user data when the component loads
  useEffect(() => {

    // Fetch all users from the API when the component mounts
    const fetchAllUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/auth/users`); // Adjust the endpoint as necessary
        setUsers(response.data); 
        setUserData(response.data[0].role);
        console.log('Fetched users:', response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchAllUsers(); 
  }, []); 

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profilePic", file);

    axios
      .post(`${apiUrl}/auth/users/${userId}/upload-profile-pic`, formData)
      .then((response) => {
        setProfilePic(response.data.profilePicUrl); // Save uploaded profile picture URL
      })
      .catch((error) => {
        console.error("There was an error uploading the profile picture!", error);
      });
  };

  const handleLogoutClick = () => {
    handleLogout();
     // Call the logout function passed from the parent component
    navigate("/"); // Redirect to the login page after logging out
  };

  return (
    <Container fluid className="d-flex flex-grow-1">
      <Row className="flex-grow-1">
        <Col md={3} lg={2} className="sidebar-col">
          <div
            className="d-flex flex-column align-items-center justify-content-center border p-5"
            style={{
              width: "250px",
              border: "1px solid #dee2e6",
              borderRadius: "5px",
              margin: "20px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="user-info mb-3 text-center" style={{ padding: "20px" }}>
              {profilePic ? (
                <img
                  src={`${apiUrl}/uploads/${profilePic}`}
                  alt="Profile"
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <FaUser size={80} className="mb-2" style={{ color: "rgb(145, 14, 14)" }} />
              )}
              <div className="text-dark">
                <p className="text-dark">Email: {userData.email}</p>
                <p className="text-dark">Contact: {userData.contactNumber}</p>
              </div>
            </div>
            <Button variant="outline-dark" className="mt-3 w-100" onClick={handleLogoutClick}>
              <FaSignOutAlt className="me-2" /> Logout
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Sidebar;
