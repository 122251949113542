import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Button, Form as BootstrapForm } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import Swal from 'sweetalert2';

const ForgotPassword = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const initialValues = {
    email: '',
    otp: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
    otp: otpSent ? Yup.string().required('OTP is required') : Yup.string(),
    newPassword: otpSent
      ? Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required')
      : Yup.string(),
    confirmNewPassword: otpSent
      ? Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required('Confirm password is required')
      : Yup.string(),
  });

  const isLocalhost = window.location.hostname === 'localhost';

  const apiUrl = isLocalhost ? 'http://localhost:5000' : 'https://www.mayuhrsolutions.com/api';

  const handleSubmit = async (values) => {
    try {
      if (!otpSent) {
        // Send OTP request
        const response = await fetch(`${apiUrl}/auth/forgot-password`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: values.email })
        });
  
        if (response.ok) {
          setOtpSent(true);
          Swal.fire({
            icon: 'success',
            title: 'OTP Sent',
            text: 'OTP has been sent to your email.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an issue sending the OTP. Please try again.',
          });
        }
      } else {
        // Submit new password
        const response = await fetch(`${apiUrl}/auth/reset-password`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: values.email,
            otp: values.otp,
            newPassword: values.newPassword
          })
        });
  
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Password Reset',
            text: 'Your password has been reset successfully.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an issue resetting your password. Please try again.',
          });
        }
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Unexpected Error',
        text: 'An error occurred. Please try again later.',
      });
    }
  };
  
  return (
    <Container className="d-flex justify-content-center align-items-center vh-100" style={{ maxWidth: '400px' }}>
      <div className="border p-4 rounded shadow-sm w-100">
        <h2 className="text-center mb-4">Forgot Password</h2>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {() => (
            <Form>
              <BootstrapForm.Group className="mb-3">
                <BootstrapForm.Label>Email<span className="text-danger">*</span></BootstrapForm.Label>
                <Field name="email" type="email" className="form-control" />
                <ErrorMessage name="email" component="div" className="text-danger" />
              </BootstrapForm.Group>

              {otpSent && (
                <>
                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>OTP</BootstrapForm.Label>
                    <Field name="otp" className="form-control" />
                    <ErrorMessage name="otp" component="div" className="text-danger" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>New Password<span className="text-danger">*</span></BootstrapForm.Label>
                    <div className="d-flex align-items-center">
                      <Field
                        name="newPassword"
                        type={viewPassword ? 'text' : 'password'}
                        className="form-control"
                      />
                      <Button variant="outline-secondary" className="ms-2" onClick={() => setViewPassword(!viewPassword)}>
                        {viewPassword ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </div>
                    <ErrorMessage name="newPassword" component="div" className="text-danger" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Confirm New Password<span className="text-danger">*</span></BootstrapForm.Label>
                    <div className="d-flex align-items-center">
                      <Field
                        name="confirmNewPassword"
                        type={viewConfirmPassword ? 'text' : 'password'}
                        className="form-control"
                      />
                      <Button variant="outline-secondary" className="ms-2" onClick={() => setViewConfirmPassword(!viewConfirmPassword)}>
                        {viewConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </div>
                    <ErrorMessage name="confirmNewPassword" component="div" className="text-danger" />
                  </BootstrapForm.Group>
                </>
              )}

              <Button type="submit" className="w-100">
                {otpSent ? 'Set New Password' : 'Send OTP'}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default ForgotPassword;
