import React, { useState, useEffect } from "react";
import { Container, Table, Button, Pagination } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom"; // For accessing jobId from URL

const SelectedCandidatePage = () => {
  const { jobId } = useParams(); // Get jobId from the URL
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  console.log("app", applications);
  const itemsPerPage = 5; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  // Pagination logic
  const totalPages = Math.ceil(applications.length / itemsPerPage);
  const paginatedApplications = applications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000" // Local development
    : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {
    axios
      .get(`${apiUrl}/shortlisted`)
      .then((response) => {
        console.log("response", response);
        setApplications(response.data);
      })
      .catch((error) => {
        console.error("Error fetching job applications:", error);
      });
  }, []);

  const handleSelectedCandidate = (candidate) => {
    const body = {
      applicant_id: candidate.id,
      name: candidate.name,
    };

    fetch(`${apiUrl}/selected`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          Swal.fire({
            title: "Success!",
            text: data.message,
            icon: "success",
            confirmButtonText: "OK",
          });
        } else if (data.error) {
          Swal.fire({
            title: "Error",
            text: data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          title: "Error",
          text: "There was a problem selecting the candidate. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };


  const handleDeleteApplication = async (applicationId) => {
    // Show confirmation dialog before deleting
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      try {
        const response = await fetch(
          `${apiUrl}/shortlisted/${applicationId}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete application");
        }

        // Refresh application data after deletion
        const updatedApplications = applications.filter(
          (app) => app.id !== applicationId
        );
        setApplications(updatedApplications);

        // Show success message
        Swal.fire("Deleted!", "Your application has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting application:", error);

        // Show error message
        Swal.fire(
          "Error!",
          "There was a problem deleting the application. Please try again.",
          "error"
        );
      }
    }
  };

  return (
    <Container>
      <h3>Applications for Job ID: {jobId}</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Applicant Name</th>
            {/* <th>Job Title</th> */}
            {/* <th>Resume</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {applications.map((app, index) => (
            <tr key={app.id}>
              <td>{index + 1}</td>
              <td>{app.name}</td>
              {/* <td>{app.title}</td> */}
              {/* <td>
                <a
                  href={`http://localhost:5000${app.resume}`} // Assuming the API returns the correct file path (e.g., /uploads/resume.pdf)
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Resume
                </a>
              </td> */}
              <td>
                <Button
                  variant="success"
                  onClick={() => handleSelectedCandidate(app)}
                  className="me-3"
                >
                  Seleceted
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteApplication(app.id, "Rejected")}
                >
                  Rejected
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination className="d-flex justify-content-end">
        <Pagination.Prev
          onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
        />
        {[...Array(totalPages).keys()].map((number) => (
          <Pagination.Item
            key={number}
            active={number + 1 === currentPage}
            onClick={() => setCurrentPage(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() =>
            currentPage < totalPages && setCurrentPage(currentPage + 1)
          }
        />
      </Pagination>
    </Container>
  );
};

export default SelectedCandidatePage;
