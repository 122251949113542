import React, { useEffect, useState } from 'react';
import { Table, Container, Spinner, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const EmployeeTable = () => {
  const [employees, setEmployees] = useState([]);
  console.log("employee", employees);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
   // Check if the app is running on localhost (development)
   const isLocalhost = window.location.hostname === "localhost";

   const apiUrl = isLocalhost
     ? "http://localhost:5000" // Local development
     : "https://www.mayuhrsolutions.com/api";

    const fetchEmployees = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/employees`);
        console.log("response", response);
        if (!response.ok) {
          throw new Error('Failed to fetch employees');
        }
        const data = await response.json();
        setEmployees(data);
      } catch (error) {
        setError(error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.message,
        });
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
    fetchEmployees();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = await Swal.fire({
      title: 'Are you sure?',
      text: "This action cannot be undone.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    });

    if (confirmDelete.isConfirmed) {
      try {
        const response = await fetch(`${apiUrl}/api/employees/${id}`, {
          method: 'DELETE',
        });
        if (!response.ok) {
          throw new Error('Failed to delete employee');
        }
        Swal.fire('Deleted!', 'Employee has been deleted.', 'success');
        // Refetch employees after deletion
        fetchEmployees();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.message,
        });
        console.error('Error deleting employee:', error);
      }
    }
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }


  const handleEdit = (employee) => {
    // Navigate to the edit route with the employee ID
    navigate(`/employee-profile/${employee.id}`);
  };

  return (
    <Container className="mt-4">
      <h2>Employee List</h2>
      {error && <p className="text-danger">{error}</p>}
      <Table striped bordered hover>
        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th>Name</th>
            <th>Position</th>
            <th>Employee Id</th>
            <th>Department</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {employees.length > 0 ? (
            employees.map((employee) => (
              <tr key={employee.id}>
                {/* <td>{employee.id}</td> */}
                <td>{employee.employeeName}</td>
                <td>{employee.jobTitle}</td>
                <td>{employee.empId}</td>
                <td>{employee.department}</td>
                <td>
                <Button variant="primary" className='me-3' onClick={() => handleEdit(employee)}>
                  Edit
                </Button>
                    <Button variant="danger" onClick={() => handleDelete(employee.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No employees found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default EmployeeTable;