import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Table, Container, Card, Breadcrumb, Alert, Spinner, Pagination } from 'react-bootstrap';
import axios from 'axios';

const DailyAttendance = () => {
  // State for attendance data from the API
  const [attendanceData, setAttendanceData] = useState([]);
  const [originalAttendanceData, setOriginalAttendanceData] = useState([]); // To keep track of the full data
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(''); // To track API errors
  // require('dotenv').config(); 
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5; // Number of rows per page

  // Filter state
  const [filters, setFilters] = useState({
    date: '',
    department: '',
    name: '',
  });

  // Validation state
  const [error, setError] = useState('');

  const formatDateForSearch = (dateString) => {
    const [day, month, year] = dateString.split('-');
    const monthMap = {
      jan: '01',
      feb: '02',
      mar: '03',
      apr: '04',
      may: '05',
      jun: '06',
      jul: '07',
      aug: '08',
      sep: '09',
      oct: '10',
      nov: '11',
      dec: '12'
    };
  
    return `${day}-${monthMap[month.toLowerCase()]}-${year}`;
  };

  // Check if the app is running on localhost (development)
const isLocalhost = window.location.hostname === 'localhost';

// Set the API URL based on whether the app is running locally or in UAT
const apiUrl = isLocalhost
  ? 'http://localhost:5000'  // Local development
  : 'https://www.mayuhrsolutions.com/api';  

  // Fetch attendance data from the backend on component mount
  useEffect(() => {
   
    const fetchAttendanceData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/attendance`);
        setAttendanceData(response.data);
        setOriginalAttendanceData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setApiError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAttendanceData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  // Handle filtering logic
  const handleSearch = () => {
  // Validation: Check if at least one field is filled
  if (!filters.date && !filters.department && !filters.name) {
    setError('Please fill at least one field.');
    return;
  }

  setError(''); // Clear error message if validation passes

  // Format the search date
  const formattedSearchDate = filters.date ? formatDateForSearch(filters.date) : '';

  const filteredData = originalAttendanceData.filter((employee) => {
    const employeeDateFormatted = formatDateForSearch(new Date(employee.date).toLocaleDateString('en-GB'));

    const matchesDate = formattedSearchDate ? employeeDateFormatted === formattedSearchDate : true;
    const matchesDepartment = filters.department ? employee.department.toLowerCase().includes(filters.department.toLowerCase()) : true;
    const matchesName = filters.name ? employee.employee_name.toLowerCase().includes(filters.name.toLowerCase()) : true;

    return matchesDate && matchesDepartment && matchesName;
  });

  setAttendanceData(filteredData);
};


  // Reset filters and display all data
  const handleCancel = () => {
    setFilters({
      date: '',
      department: '',
      name: '',
    });
    setAttendanceData(originalAttendanceData); // Reset to the original dataset
    setError(''); // Clear error message
  };

  // Formatting date to 'DD/MM/YYYY'
  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Pagination calculation
  const totalPages = Math.ceil(attendanceData.length / rowsPerPage);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = attendanceData.slice(indexOfFirstRow, indexOfLastRow);

  // Page numbers for pagination
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Container fluid>
      <h1 className=''style={{color:'rgb(145, 14, 14)'}}>Daily Attendance</h1>
      <Breadcrumb>
        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Daily Attendance</Breadcrumb.Item>
      </Breadcrumb>

      <Card className='mt-4 p-4'>
        <h4>Filter</h4>
        {/* Filter Form */}
        <Form className="mb-4">
          {error && <Alert variant="danger">{error}</Alert>}
          <Row className="align-items-center">
            <Col md={3} sm={12} className="mb-2 mb-md-0">
              <Form.Group controlId="filterDate">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  value={filters.date}
                  onChange={(e) => setFilters({ ...filters, date: e.target.value })}
                />
              </Form.Group>
            </Col>

            <Col md={3} sm={12} className="mb-2 mb-md-0">
              <Form.Group controlId="filterDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter department"
                  value={filters.department}
                  onChange={(e) => setFilters({ ...filters, department: e.target.value })}
                />
              </Form.Group>
            </Col>

            <Col md={3} sm={12} className="mb-2 mb-md-0">
              <Form.Group controlId="filterName">
                <Form.Label>Employee Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter employee name"
                  value={filters.name}
                  onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                />
              </Form.Group>
            </Col>

            {/* Buttons */}
            <Col md={3} sm={12} className="text-md-end mt-2 mt-md-0">
              <Button variant="primary" className="me-2" onClick={handleSearch}>
                Search
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      {/* Attendance Table */}
      <Card className='mt-5'>
        <h4 className='mt-3 p-3'>Employee Attendance</h4>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="5" className="text-center">
                {/* <Spinner animation="border" variant="primary" /> Loading... */}
              </td>
            </tr>
          </tbody>
        ) : apiError ? (
          <tbody>
            <tr>
              <td colSpan="5" className="text-center text-danger">{apiError}</td>
            </tr>
          </tbody>
        ) : (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Employee Name</th>
                <th>Department</th>
                <th>Date</th>
                <th>Attendance</th>
              </tr>
            </thead>
            <tbody>
              {currentRows.length > 0 ? (
                currentRows.map((row, index) => (
                  <tr key={row.id}>
                    <td>{indexOfFirstRow + index + 1}</td>
                    <td>{row.employee_name}</td>
                    <td>{row.department}</td>
                    <td>{formatDate(row.date)}</td>
                    <td>{row.attendance}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}

        {/* Pagination */}
        {!loading && !apiError && (
          <Pagination className="d-flex justify-content-end mt-4">
            <Pagination.Prev
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
            />
            {pageNumbers.map((number) => (
              <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => handlePageChange(number)}
              >
                {number}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
            />
          </Pagination>
        )}
      </Card>
    </Container>
  );
};

export default DailyAttendance;
