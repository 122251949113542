import React, { useState } from 'react';
import axios from 'axios';
import { Card, Row, Col, Breadcrumb, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const OtpVerificationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    otp: ''
  });
  const [errors, setErrors] = useState({});

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Validate form data
  const validate = () => {
    let tempErrors = {};

    if (!formData.email) {
      tempErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = 'Invalid email address';
    }

    if (!formData.otp) {
      tempErrors.otp = 'OTP is required';
    } else if (formData.otp.length !== 6) {
      tempErrors.otp = 'OTP must be 6 characters';
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000" // Local development
    : "https://www.mayuhrsolutions.com/api";

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        // Call the backend API to verify OTP
        const response = await axios.post(`${apiUrl}/verify-otp`, formData);

        // Show SweetAlert success message
        Swal.fire({
          icon: 'success',
          title: 'OTP Verified',
          text: response.data.message || 'Your OTP has been successfully verified!',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            // Navigate to the login page after the success message is confirmed
            navigate('/candidate-login');
          }
        });
      } catch (error) {
        // Show SweetAlert error message
        Swal.fire({
          icon: 'error',
          title: 'OTP Verification Failed',
          text: error.response?.data.message || 'Error verifying OTP. Please try again.',
        });
      }
    }
  };

  return (
    <div className="container mt-5">
      <h2 className='registration-heading'>OTP Verification</h2>
      {/* <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>OTP Verification</Breadcrumb.Item>
      </Breadcrumb> */}
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="shadow border-0">
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={formData.email}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formOtp" className="mt-3">
                  <Form.Label>OTP<span className='text-danger'>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="otp"
                    onChange={handleChange}
                    value={formData.otp}
                    isInvalid={!!errors.otp}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.otp}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="d-flex justify-content-center">
                  <Button type="submit" className="btn btn-primary mt-3">
                    Verify OTP
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OtpVerificationForm;
