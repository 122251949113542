import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container, Breadcrumb } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';

const EmployeeProfile = () => {
  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    employeeName: '',
    email: '',
    contactNumber: '',
    gender: '',
    dob: '',
    address: '',
    jobTitle: '',
    empId: '',
    department: '',
    team: '',
    teamLeader: '',
    manager: '',
    joiningDate: '',
    education: ''
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Function to validate the form fields
  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    // Employee Name validation
    if (!formData.employeeName) {
      formErrors.employeeName = 'Employee Name is required';
      isValid = false;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      formErrors.email = 'Email is required';
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      formErrors.email = 'Invalid email format';
      isValid = false;
    }

    // Contact number validation (must start with 9, 8, or 7 and be 10 digits long)
    const contactNumberRegex = /^[789]\d{9}$/;
    if (!formData.contactNumber) {
      formErrors.contactNumber = 'Contact Number is required';
      isValid = false;
    } else if (!contactNumberRegex.test(formData.contactNumber)) {
      formErrors.contactNumber = 'Contact Number must start with 9, 8, or 7 and be 10 digits long';
      isValid = false;
    }

    // Gender validation
    if (!formData.gender) {
      formErrors.gender = 'Gender is required';
      isValid = false;
    }

    // Date of birth validation
    if (!formData.dob) {
      formErrors.dob = 'Date of Birth is required';
      isValid = false;
    }

    // Address validation
    if (!formData.address) {
      formErrors.address = 'Address is required';
      isValid = false;
    }

    // Job Title validation
    if (!formData.jobTitle) {
      formErrors.jobTitle = 'Job Title is required';
      isValid = false;
    }

    // Employee ID validation
    if (!formData.empId) {
      formErrors.empId = 'Employee ID is required';
      isValid = false;
    }

    // Department validation
    if (!formData.department) {
      formErrors.department = 'Department is required';
      isValid = false;
    }

    // Team validation
    if (!formData.team) {
      formErrors.team = 'Team is required';
      isValid = false;
    }

    // Team Leader validation
    if (!formData.teamLeader) {
      formErrors.teamLeader = 'Team Leader is required';
      isValid = false;
    }

    // Manager validation
    if (!formData.manager) {
      formErrors.manager = 'Manager is required';
      isValid = false;
    }

    // Joining Date validation
    if (!formData.joiningDate) {
      formErrors.joiningDate = 'Joining Date is required';
      isValid = false;
    }

    // Education validation
    if (!formData.education) {
      formErrors.education = 'Education is required';
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Check if the app is running on localhost (development)
  const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000" // Local development
    : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {
    if (id) {
      const fetchEmployeeData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/employees/${id}`);
          let employeeData = response.data;
  
          // Format the date fields (assuming they come in a different format)
          if (employeeData.dob) {
            employeeData.dob = moment(employeeData.dob).format('YYYY-MM-DD'); // Convert DOB to the right format
          }
  
          if (employeeData.joiningDate) {
            employeeData.joiningDate = moment(employeeData.joiningDate).format('YYYY-MM-DD'); // Convert joiningDate to the right format
          }
  
          setFormData(employeeData); // Populate the form with the fetched and formatted data
        } catch (error) {
          console.error('Error fetching employee data:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Could not fetch employee data.',
          });
        }
      };
  
      fetchEmployeeData();
    }
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Current ID:", id); // Check the ID value
  
    if (id) {
      // If updating an existing record (PUT)
      axios.put(`${apiUrl}/employees/${id}`, formData)
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Employee data updated successfully.'
          }).then(() => {
            navigate('/employee-info'); // Redirect after successful update
          });
        })
        .catch((error) => {
          console.error('Error updating data', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was a problem updating the employee data.'
          });
        });
    } else {
      // If creating a new record (POST)
      axios.post(`${apiUrl}/employees`, formData)
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Employee added successfully.'
          }).then(() => {
            navigate('/employee-info'); // Redirect after successful creation
          });
        })
        .catch((error) => {
          console.error('Error saving data', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was a problem adding the employee data.'
          });
        });
    }
  };
  

  // Handle form reset (Clear fields)
  const handleCancel = () => {
    setFormData({
      employeeName: '',
      email: '',
      contactNumber: '',
      gender: '',
      dob: '',
      address: '',
      jobTitle: '',
      empId: '',
      department: '',
      team: '',
      teamLeader: '',
      manager: '',
      joiningDate: '',
      education: ''
    });
    setErrors({});
  };

  // Navigate to upload document page
  const navigateToUpload = () => {
    navigate('/legal-documents');
  };

  return (
    <Container>
      <h2 className='text-primary'>Employee Profile</h2>
      <Breadcrumb>
        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Employee Profile</Breadcrumb.Item>
      </Breadcrumb>

      <div className="p-4 border rounded shadow-lg bg-light">
        <Form onSubmit={handleSubmit}>
          <h4>Personal Information</h4>
          <Row>
            <Col md={4}>
              <Form.Group controlId="employeeName">
                <Form.Label>Employee Name<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="employeeName"
                  value={formData.employeeName}
                  onChange={handleInputChange}
                  isInvalid={!!errors.employeeName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.employeeName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="email">
                <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="contactNumber">
                <Form.Label>Contact Number<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="tel"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleInputChange}
                  isInvalid={!!errors.contactNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contactNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group controlId="gender">
                <Form.Label>Gender<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  isInvalid={!!errors.gender}
                >
                  <option value="">Select...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.gender}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="dob">
                <Form.Label>Date of Birth <span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleInputChange}
                  isInvalid={!!errors.dob}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dob}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="address">
                <Form.Label>Address<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <h4 className='mt-2'>Professional Information</h4>
          <Row>
            <Col md={4}>
              <Form.Group controlId="jobTitle">
                <Form.Label>Job Title<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleInputChange}
                  isInvalid={!!errors.jobTitle}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.jobTitle}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="empId">
                <Form.Label>Employee ID<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="empId"
                  value={formData.empId}
                  onChange={handleInputChange}
                  isInvalid={!!errors.empId}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.empId}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="department">
                <Form.Label>Department<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="department"
                  value={formData.department}
                  onChange={handleInputChange}
                  isInvalid={!!errors.department}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.department}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group controlId="team">
                <Form.Label>Team<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="team"
                  value={formData.team}
                  onChange={handleInputChange}
                  isInvalid={!!errors.team}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.team}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="teamLeader">
                <Form.Label>Team Leader<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="teamLeader"
                  value={formData.teamLeader}
                  onChange={handleInputChange}
                  isInvalid={!!errors.teamLeader}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.teamLeader}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="manager">
                <Form.Label>Manager<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="manager"
                  value={formData.manager}
                  onChange={handleInputChange}
                  isInvalid={!!errors.manager}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.manager}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group controlId="joiningDate">
                <Form.Label>Joining Date<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="date"
                  name="joiningDate"
                  value={formData.joiningDate}
                  onChange={handleInputChange}
                  isInvalid={!!errors.joiningDate}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.joiningDate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="education">
                <Form.Label>Education<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="education"
                  value={formData.education}
                  onChange={handleInputChange}
                  isInvalid={!!errors.education}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.education}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
            <Button variant="outline-info" className="mt-4" onClick={navigateToUpload}>
          Upload Document
        </Button>
            </Col>
          </Row>

          <div className="mt-3">
          <Button variant="primary" type="submit" className='me-2'>
              Submit
            </Button>
            <Button variant="secondary" className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
          
          </div>
        </Form>

        
      </div>
    </Container>
  );
};
export default EmployeeProfile;
