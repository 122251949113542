// src/components/RegistrationForm.js

import React from 'react';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Button, Col, Row, Container} from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    contactNumber: '',
    dob: '',
    address: '',
    password: '',
    confirmPassword: ''
});
const [errors, setErrors] = useState({});
const navigate = useNavigate();
// Form field change handler
const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

// Form validation
const validate = () => {
    let formErrors = {};
    if (!formData.fullName) formErrors.fullName = 'Full Name is required';
    if (!formData.email) formErrors.email = 'Email is required';
    if (!formData.contactNumber) formErrors.contactNumber = 'Contact Number is required';
    if (!formData.dob) formErrors.dob = 'Date of Birth is required';
    if (!formData.address) formErrors.address = 'Address is required';
    if (!formData.password) formErrors.password = 'Password is required';
    if (formData.password !== formData.confirmPassword) formErrors.confirmPassword = 'Passwords do not match';
    return formErrors;
};

const isLocalhost = window.location.hostname === "localhost";

  const apiUrl = isLocalhost
    ? "http://localhost:5000" // Local development
    : "https://www.mayuhrsolutions.com/api";

// Form submit handler

const handleSubmit = (e) => {
  e.preventDefault();
  const validationErrors = validate();
  
  if (Object.keys(validationErrors).length === 0) {
      // Example headers: Replace 'Bearer token' with actual token if needed
      const headers = {
          'Content-Type': 'application/json',
          
      };
      
      axios.post(`${apiUrl}/register`, formData, { headers })
          .then(response => {
              Swal.fire({
                  title: 'Success!',
                  text: 'Registration successful, please check your email for OTP.',
                  icon: 'success',
                  confirmButtonText: 'OK',
              }).then((result) => {
                  if (result.isConfirmed) {
                      // Navigate to OTP verification page
                      navigate('/otp-verify-candidate');
                  }
              });
          })
          .catch(error => {
              Swal.fire({
                  title: 'Error!',
                  text: 'Registration failed, please try again.',
                  icon: 'error',
                  confirmButtonText: 'OK',
              });
          });
  } else {
      setErrors(validationErrors);
  }
};

// Cancel button handler
const handleCancel = () => {
    setFormData({
        fullName: '',
        email: '',
        contactNumber: '',
        dob: '',
        address: '',
        password: '',
        confirmPassword: ''
    });
    setErrors({});
};

  return (
    <div className="container">
      <Container className="registration-container mt-5">
        <h2 className="registration-heading">Registration Form</h2>
        <div className="form-wrapper">
      <Form onSubmit={handleSubmit} className=" mt-2 ">
        <Row className='mt-3'>
          <Col md={6}>
            <Form.Group controlId="formFullName">
              <Form.Label>Full Name<span className='text-danger'>*</span></Form.Label>
              <Form.Control
               type="text" name="fullName" value={formData.fullName} onChange={handleChange}
              />
              {errors.fullName && <p className="text-danger">{errors.fullName.message}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
              <Form.Control
               type="email" name="email" value={formData.email} onChange={handleChange}
              />
              {errors.email && <p className="text-danger">{errors.email.message}</p>}
            </Form.Group>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col md={6}>
            <Form.Group controlId="formContactNumber">
              <Form.Label>Contact Number<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type="text" name="contactNumber" value={formData.contactNumber} onChange={handleChange}
              />
              {errors.contactNumber && <p className="text-danger">{errors.contactNumber.message}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formDob">
              <Form.Label>Date of Birth<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type="date" name="dob" value={formData.dob} onChange={handleChange}
              />
              {errors.dob && <p className="text-danger">{errors.dob.message}</p>}
            </Form.Group>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col md={6}>
            <Form.Group controlId="formPassword">
              <Form.Label>Password<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type="password" name="password" value={formData.password} onChange={handleChange} 
              />
              {errors.password && <p className="text-danger">{errors.password.message}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Confirm Password<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange}
              />
              {errors.confirmPassword && <p className="text-danger">{errors.confirmPassword.message}</p>}
            </Form.Group>
          </Col>
        </Row>
        <Row>
        <Col md={6}>
            <Form.Group controlId="formAddress">
              <Form.Label>Address<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type="text" name="address" value={formData.address} onChange={handleChange}
              />
              {errors.address && <p className="text-danger">{errors.address.message}</p>}
            </Form.Group>
          </Col>
          </Row>

        <div className="mt-3">
        <Button variant="primary" className='me-3' type="submit">
            Submit
          </Button>
          <Button variant="secondary"onClick={handleCancel}>
            Cancel
          </Button>
          
        </div>
      </Form>
      </div>
      </Container>
    </div>
  );
};

export default RegistrationForm;
