import React, { useState, useEffect } from "react";
import { Container, Table, Button, Dropdown, Modal, Pagination, Form, Breadcrumb } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const ApplicationsPage = () => {
  const { jobId } = useParams(); 
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const itemsPerPage = 5; 
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(applications.length / itemsPerPage);
  const paginatedApplications = applications.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const isLocalhost = window.location.hostname === "localhost";
  const apiUrl = isLocalhost ? "http://localhost:5000" : "https://www.mayuhrsolutions.com/api";

  useEffect(() => {
    // Fetch all applications
    axios.get(`${apiUrl}/applications/all`)
      .then(response => {
        setApplications(response.data);
        // Set initial statuses from the fetched data
        const initialStatuses = response.data.reduce((acc, app) => {
          acc[app.id] = app.status || "Pending";
          return acc;
        }, {});
        setCurrentStatus(initialStatuses);
      })
      .catch(error => console.error("Error fetching job applications:", error));
  }, []);

  const statusOptions = ["Pending", "Shortlisted", "Selected", "Rejected"];
  const reasons = ["Not qualified", "Selected from HR interview Call", "Selected from Manager Round"];

  const handleOpenModal = (candidate, newStatus) => {
    setSelectedCandidate(candidate);
    setCurrentStatus((prev) => ({ ...prev, [candidate.id]: newStatus }));
    setShowModal(true);
  };

  const handleSubmit = () => {
    const body = {
      id: selectedCandidate.id,
      status: currentStatus[selectedCandidate.id],
      reasons: selectedReasons,
    };
    
    // Update status on the server using PATCH request
    fetch(`${apiUrl}/applications/${selectedCandidate.id}/status`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        Swal.fire({
          title: "Success!",
          text: data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
          setShowModal(false);
          // navigate("/candidate-dashboard");
        });
      })
      .catch((error) => {
        Swal.fire("Error", "Failed to update candidate status.", "error");
      });
  };

  const handleStatusChange = (candidate, newStatus) => {
    if (newStatus !== "Pending") {
      handleOpenModal(candidate, newStatus);
    } else {
      // Update status immediately without modal if it's pending
      setCurrentStatus((prev) => ({ ...prev, [candidate.id]: "Pending" }));
    }
  };

  return (
    <Container>
      <h3 className="registration-heading mt-3">Applications for Jobs</h3>
      <Breadcrumb>
        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/admin-dashboard">
          Admin Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>View Job Applications</Breadcrumb.Item>
      </Breadcrumb>
      <Table striped bordered hover className="shadow ">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Applicant Name</th>
            <th>Resume</th>
            <th>Status</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>
          {paginatedApplications.map((app, index) => (
            <tr key={app.id}>
              <td>{index + 1}</td>
              <td>{app.name}</td>
              <td>
                <a href={`${apiUrl}/api/resume/download/${app.resume}`} target="_blank" rel="noopener noreferrer">
                  View Resume
                </a>
              </td>
              <td>
                 {/* If status is 'Rejected', display a red button, otherwise show the dropdown */}
                 {currentStatus[app.id] === "Rejected" ? (
                  <Button variant="danger" disabled>
                    Rejected
                  </Button>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {currentStatus[app.id] || "Pending"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {statusOptions.map((status) => (
                        <Dropdown.Item key={status} onClick={() => handleStatusChange(app, status)}>
                          {status}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </td>
              <td>
              {app.reasons}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination className="d-flex justify-content-end">
        <Pagination.Prev onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} />
        {[...Array(totalPages).keys()].map((number) => (
          <Pagination.Item key={number} active={number + 1 === currentPage} onClick={() => setCurrentPage(number + 1)}>
            {number + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)} />
      </Pagination>

      {/* Modal for status change */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Reason for {currentStatus[selectedCandidate?.id]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {reasons.map((reason) => (
              <Form.Check
                key={reason}
                type="checkbox"
                label={reason}
                value={reason}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedReasons([...selectedReasons, reason]);
                  } else {
                    setSelectedReasons(selectedReasons.filter((r) => r !== reason));
                  }
                }}
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ApplicationsPage;
